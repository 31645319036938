import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: '',
  multicall: '0x8Dd3716Bf2a7B823C1D08Ff1EF5FD77c70e80171',
  authorizer: '0x389eCcF6Ef99d01a9Cbb728BD79F9b945Cd7Ea25',
  vault: '0x723F40720836a03f1F20441FbDa3D109fc99640F',
  weightedPoolFactory: '0xec205bcd4177CF32f7Dc4D6aa478cdf7Dc176d37',
  stablePoolFactory: '',
  weth: '0xA51894664A773981C6C112C43ce576f315d5b1B6',
  stMATIC: '',
  rETH: '',
  stETH: '',
  wstETH: '',
  lidoRelayer: '',
  balancerHelpers: '0x0C9862Bd6307D5D90D07ca0AAE39871100B8e7EB',
  batchRelayer: '0xc017907866c55DD6C02B71f92a64DcD9a637A1a3',
  gaugeFactory: '0xB136dFCC309815D5AA4b5C1382927042b4d1d421',
  balancerMinter: '0xcfa8edcf8b7cEf0e3bD62679b4C3efc7C5469cC6',
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '',
  veBALHelpers: '0x0C9862Bd6307D5D90D07ca0AAE39871100B8e7EB',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeRewardsHelper: '0xCFE79ba3bb1dc2C97fB591e14e32628136c2bCfc',
};

export default contracts;
